<template>
  <v-card>
    <v-container>
      <v-card-title class="mt-n3">
        <span class="primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form v-model="isFormValid" @submit.prevent="saveCompLibroIva">
          <v-row class="mt-n5 mb-0">
            <!-- Razon social -->
            <v-col cols="7" md="7">
              <v-text-field
                type="text"
                outlined
                dense
                :disabled="disableCampos"
                label="Razón social"
                :rules="
                  rules.required.concat([
                    rules.maxLength(razonSocial, 50),
                    rules.requiredTrim(razonSocial),
                  ])
                "
                v-model="razonSocial"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- tipo de comprobante -->
            <v-col cols="5" md="5">
              <v-autocomplete
                v-model="tipoComprobanteSelected"
                :items="tipoComprobanteItems"
                :rules="rules.required"
                label="Tipo de comprobante"
                return-object
                item-text="value"
                item-value="id"
                :disabled="disableCampos"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mt-n8">
            <!-- Letra -->
            <v-col cols="2" md="2">
              <v-text-field
                type="text"
                outlined
                dense
                :rules="rules.required"
                :disabled="disableCampos"
                v-mask="'A'"
                label="Letra"
                v-model="letra"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Sucursal -->
            <v-col cols="3" md="3">
              <v-text-field
                type="text"
                outlined
                :disabled="disableCampos"
                dense
                :rules="[rules.decimalGreaterThanZero(parseInt(sucursal))]"
                label="Sucursal"
                v-mask="'#####'"
                v-model="sucursal"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- numero -->
            <v-col cols="3">
              <v-text-field
                v-model.trim="numero"
                type="text"
                label="Número"
                :disabled="disableCampos"
                :rules="[rules.decimalGreaterThanZero(parseInt(numero))]"
                dense
                v-mask="'########'"
                outlined
              ></v-text-field>
            </v-col>
            <!-- fecha -->
            <v-col cols="4" md="4">
              <v-menu
                ref="fecha-desde"
                v-model="menuFechaComp"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaCompSelected"
                    label="Fecha"
                    :append-icon="calendarIcon"
                    :disabled="disableCampos"
                    v-bind="attrs"
                    autocomplete="not"
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaComp = parseDateToIso(fechaCompSelected)"
                    outlined
                    dense
                    clearable
                    v-mask="'##/##/####'"
                    v-on="on"
                    :rules="rules.required.concat(rules.validDate)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaComp"
                  no-title
                  @change="fechaCompSelected = formatDate(fechaComp)"
                  @input="menuFechaComp = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-card-actions class="mt-n8 mb-n5">
            <v-btn
              class="ml-n2 mr-4"
              outlined
              small
              :disabled="disableBtnDelete"
              @click="deleteComprobante"
            >
              Eliminar comprobante
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn outlined small @click="closeModal"> Cerrar </v-btn>
            <v-btn
              type="submit"
              small
              :loading="loadingSave"
              :disabled="!isFormValid"
              color="primary"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
      <DeleteDialog
        :titleProp="titleDelete"
        :maxWidth="'35%'"
        :textDelete="textDelete"
        :isLoading="loadingBtnDelete"
        :openDelete.sync="showDeleteModal"
        @onDeleteItem="secondConfirmDelete()"
      />
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
export default {
  name: "ComprobanteLibroIVACompras",
  directives: { mask },
  components: {
    DeleteDialog,
  },
  props: {
    itemToAsigCompLibroIva: {
      type: Object,
    },
  },
  data: () => ({
    rules: rules,
    calendarIcon: enums.icons.CALENDAR,
    titleDelete: "¿Desea eliminar el comprobante?",
    formEditTitle: "Comprobante para libro IVA compras",
    razonSocial: null,
    letra: null,
    sucursal: null,
    numero: null,
    tipoComprobanteSelected: null,
    tipoComprobanteItems: [],
    menuFechaComp: false,
    fechaSiguiente: null,
    fechaCompSelected: "",
    fechaComp: null,
    isFormValid: false,
    disableBtnDelete: true,
    loadingSave: false,
    disableCampos: false,
    textDelete: null,
    loadingBtnDelete: false,
    showDeleteModal: false,
  }),
  created() {
    this.setLibroIvaCompras();
  },
  methods: {
    ...mapActions({
      validarAsignacionDeComprobante: "proveedores/validarAsignacionDeComprobante",
      listarTipoComprobanteParaAsignacion: "proveedores/listarTipoComprobanteParaAsignacion",
      editarAsignacionDeComprobante: "proveedores/editarAsignacionDeComprobante",
      grabarAsignacionDeComprobante: "proveedores/grabarAsignacionDeComprobante",
      borrarAsignacionDeComprobante: "proveedores/borrarAsignacionDeComprobante",
      setAlert: "user/setAlert",
    }),
    async setLibroIvaCompras() {
      const responseValidacionComp = await this.validarAsignacionDeComprobante(this.itemToAsigCompLibroIva.mProvId);
      if (responseValidacionComp.idError > 0) {
        this.setAlert({
          type: "error",
          message: responseValidacionComp.mensaje,
        });
        this.isFormValid = false;
        this.disableCampos = true;
      } else {
        this.setTipoComprobantes();
        this.setInfoComprobante();
      }
    },
    async setTipoComprobantes() {
      const responseTiposComp = await this.listarTipoComprobanteParaAsignacion(this.itemToAsigCompLibroIva.entFacId);
      this.tipoComprobanteItems = responseTiposComp;
    },
    async setInfoComprobante() {
      const responseGetInfoComp = await this.editarAsignacionDeComprobante(this.itemToAsigCompLibroIva.mProvId);
      this.razonSocial = responseGetInfoComp.razonSocialIvaCOmp;
      this.tipoComprobanteSelected = responseGetInfoComp.tCompIdIvaComp;
      this.letra = responseGetInfoComp.letraIvaCOmp;
      this.sucursal = responseGetInfoComp.sUcIvaCOmp;
      this.numero = responseGetInfoComp.nroIvaCOmp;
      if (responseGetInfoComp.fechaIvaComp != null) {
        this.fechaComp = this.parseDateToIso(responseGetInfoComp.fechaIvaCompString);
        this.fechaCompSelected = responseGetInfoComp.fechaIvaCompString;
      }
      if (
        this.razonSocial !== null ||
        this.tipoComprobanteSelected !== null ||
        this.letra !== null ||
        this.sucursal !== null ||
        this.numero !== null ||
        this.fechaComp !== null
      )
        this.disableBtnDelete = false;
      if (this.razonSocial == null)
        this.razonSocial = this.itemToAsigCompLibroIva.provNombreSinCod;
    },
    async saveCompLibroIva() {
      this.isFormValid = false;
      const dataToSave = {
        MProvId: this.itemToAsigCompLibroIva.mProvId,
        TCompIdIvaComp:
          this.tipoComprobanteSelected.id == undefined
            ? this.tipoComprobanteSelected
            : this.tipoComprobanteSelected.id,
        LetraIvaCOmp: this.letra,
        SUcIvaCOmp: parseInt(this.sucursal, 10),
        NroIvaCOmp: parseInt(this.numero, 10),
        FechaIvaComp: this.fechaComp,
        RazonSocialIvaCOmp: this.razonSocial,
        FechaIvaCompString: "",
      };
      try {
        const response = await this.grabarAsignacionDeComprobante(dataToSave);
        if (response) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
          this.isFormValid = true;
        }
      } catch (error) {
        this.isFormValid = true;
      }
    },
    async deleteComprobante() {
      this.showDeleteModal = true;
    },
    async secondConfirmDelete() {
      this.disableBtnDelete = true;
      try {
        const responseDelete = await this.borrarAsignacionDeComprobante(this.itemToAsigCompLibroIva.mProvId);
        if (responseDelete.idError == 0) {
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.closeModal();
          this.disableBtnDelete = false;
          this.showDeleteModal = false;
        }
      } catch (error) {
        this.disableBtnDelete = false;
        this.showDeleteModal = false;
      }
    },
    closeModal() {
      this.$emit("closeModalCompIva");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
  },
};
</script>