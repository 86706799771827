<template>
  <v-card>
    <v-container fluid>
      <v-layout>
        <v-flex>
          <v-data-table
            :headers="headers"
            item-key="mProvId"
            hide-default-footer
            :items="comprobantesItems"
            :loading="loadingTable"
            loading-text="Cargando datos"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row class="pb-0">
                  <v-col cols="12" md="10" align="start" class="pt-0">
                    <v-card-title class="pl-1 primary--text">{{
                      title
                    }}</v-card-title>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="havePermisoCompIvaPan">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openAsignarCompLibroIva(item)"
                  >
                    {{ paperIcon }}
                  </v-icon>
                </template>
                <span>Comp IVA</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToVerComprobante(item)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver comprobante</span>
              </v-tooltip>
              <template v-if="!item.esOp">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="item.esNc && canEdit"
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="
                        () => {
                          if (item.esNc) goToNotaCredito(item.mProvId);
                        }
                      "
                    >
                      {{ editIcon }}
                    </v-icon>
                  </template>
                  <span>Editar comprobante</span>
                </v-tooltip>
              </template>
              <template v-if="item.esOp">
                <v-menu
                  transition="scale-transition"
                  bottom
                  origin="center center"
                >
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-icon v-if="canEdit" v-bind="attrs" size="20" v-on="menu">
                      {{ editIcon }}
                    </v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="i in editOpOptions"
                      :key="i.id"
                      link
                      @click="navigateEditComprobanteOp(i.id, item)"
                    >
                      <v-list-item-title>{{ i.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <v-tooltip v-if="canDelete" left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToDeleteComprobante(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar comprobante</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.esOp && canRevert"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToRevertOp(item)"
                  >
                    {{ reverseIcon }}
                  </v-icon>
                </template>
                <span>Revertir orden de pago</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-row>
            <v-col cols="12" class="text-right" align="end">
              <v-btn outlined @click="closeModal"> Cerrar </v-btn>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog
      v-if="openModalDelete"
      v-model="openModalDelete"
      max-width="75%"
      @keydown.esc="closeModalFirst"
      persistent
    >
      <DetalleComprobanteProvDialog
        :isDelete="true"
        @closeModalDelete="closeModalFirst"
        :paramsToSeeCompDialog="paramsToDelete"
      ></DetalleComprobanteProvDialog>
    </v-dialog>
    <v-dialog
      v-if="openModalAsigCompIva"
      v-model="openModalAsigCompIva"
      max-width="50%"
      @keydown.esc="openModalAsigCompIva = false"
      persistent
    >
      <ComprobanteLibroIVACompras
        @closeModalCompIva="closeModalCompIva"
        :itemToAsigCompLibroIva="itemToAsigCompLibroIva"
      ></ComprobanteLibroIVACompras>
    </v-dialog>
    <!-- MODAL REVERTIR OP -->
    <v-dialog
      v-model="openModalRevertOp"
      v-if="openModalRevertOp"
      @keydown.esc="openModalRevertOp = false"
      max-width="87%"
      persistent
    >
      <RevertirOrdenDePago
        :movProvId="movProvIdToRevert"
        @toggleModalRevertOp="openModalRevertOp = !openModalRevertOp"
      ></RevertirOrdenDePago>
    </v-dialog>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import DetalleComprobanteProvDialog from "@/components/modules/proveedores/cuentaCorriente/DetalleComprobanteProvDialog.vue";
import ComprobanteLibroIVACompras from "@/components/modules/proveedores/ComprobanteLibroIVACompras.vue";
import { mapActions } from "vuex";
import RevertirOrdenDePago from "@/components/modules/proveedores/ordenesPago/RevertirOrdenDePago.vue";

export default {
  name: "ComprobantesCancelatoriosProv",
  components: {
    DetalleComprobanteProvDialog,
    ComprobanteLibroIVACompras,
    RevertirOrdenDePago,
  },
  props: {
    compId: {
      type: Number,
      required: false,
      default: null,
    },
    compCancelItems: {
      type: Array,
      required: false,
      default: [],
    },
    monedaObject: {
      type: Object,
      required: false,
      default: [],
    },
    havePermisoCompIva: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      title: `Comprobantes cancelatorios`,
      seeIcon: enums.icons.SEE,
      deleteIcon: enums.icons.DELETE,
      paperIcon: enums.icons.PAPER_WITH_PENCIL,
      editIcon: enums.icons.EDIT,
      reverseIcon: enums.icons.REVERSE,
      canDelete: false,
      canRevert: false,
      canEdit: false,
      havePermisoCompIvaPan: false,
      paramsToDelete: null,
      openModalDelete: false,
      loadingTable: false,
      monedaDefecto: null,
      monedasItems: null,
      openModalAsigCompIva: false,
      itemToAsigCompLibroIva: null,
      movProvIdToRevert: null,
      openModalRevertOp: false,
      comprobantesItems: [],
      editOpOptions: [
        { id: 0, title: "Editar solo imputación" },
        { id: 1, title: "Editar" },
      ],
      headers: [
        {
          text: "Tipo de comprobante",
          align: "start",
          sortable: false,
          value: "tipoComp",
        },
        {
          text: "Número",
          sortable: false,
          value: "nroComp",
        },
        {
          text: "Fecha",
          sortable: false,
          value: "fecha",
        },
        {
          text: "Fecha contable",
          sortable: false,
          value: "fecContable",
        },
        {
          text: "Importe cancelado",
          sortable: false,
          value: "cancelado",
          align: "end",
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
      allowedActions: null,
    };
  },
  created() {
    if (this.havePermisoCompIva) this.havePermisoCompIvaPan = true;
    this.setPermisos();
    this.setComprobantesCancelatorios();
  },
  methods: {
    ...mapActions({
      canEditTotalOp: "proveedores/canEditTotalComprobante",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .ELIMINAR_COMPROBANTE_CTACTE_PROVEEDORES:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_COMPROBANTE_CC_PROV:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.REVERTIR_OP_CC_PROV:
            this.canRevert = true;
            break;
          default:
            break;
        }
      });
    },
    async setComprobantesCancelatorios() {
      const monedasResponse = await this.getMonedasTaxonomy("habListado");
      this.monedasItems = monedasResponse;
      this.monedaDefecto = monedasResponse.find((x) => x.bool == true);
      this.loadingTable = true;
      const itemsFiltrados = this.compCancelItems.filter(
        (x) => x.mProvIdDeu == this.compId
      );
      this.comprobantesItems = itemsFiltrados;
      this.loadingTable = false;
    },
    navigateEditComprobanteOp(id, item) {
      if (id == 0) {
        this.$router.push({
          name: "NuevaOrdenDePago",
          params: {
            mProvId: item.mProvId,
            tipoEdicion: {
              canEditImputacion: true,
              canEditTotal: false,
              mensaje: null,
            },
            empresaSelected: this.empresaSelected
              ? this.empresaSelected.id
              : null,
            provCod: this.proveedorSelected
              ? this.proveedorSelected.value2
              : null,
            fechaDesde: this.fechaDesde,
            fechaHasta: this.fechaHasta,
            routeToGoBack: "CuentaCorrienteProveedores",
          },
        });
      }
      if (id == 1) this.goToOrdenPago(item.mProvId);
    },
    goToVerComprobante(item) {
      if (item.esOp) {
        let selectMoneda = null;
        if (item.monedaNombreComp == "")
          selectMoneda = this.monedasItems.find((x) => x.bool == true);
        else
          selectMoneda = this.monedasItems.find(
            (x) => x.value == item.monedaNombreComp
          );
        this.$router.push({
          name: "VerOrdenDePago",
          params: {
            movProvId: item.mProvId,
            isCtaCte: true,
            monedaToDetalle: this.monedaObject.monedaToDetalle,
            fechaCotizaToDetalle: this.monedaObject.fechaCotizaToDetalle,
            monedaDefecto: this.monedaDefecto,
            monedaProveedor: selectMoneda,
            goBackName: "CuentaCorrienteProveedores",
          },
        });
      } else {
        let selectMoneda = null;
        if (item.monedaNombreComp == "")
          selectMoneda = this.monedasItems.find((x) => x.bool == true);
        else
          selectMoneda = this.monedasItems.find(
            (x) => x.value == item.monedaNombreComp
          );

        this.$router.push({
          name: "DetalleComprobanteProv",
          params: {
            movProvId: item.mProvId,
            monedaToDetalle: this.monedaObject.monedaToDetalle,
            fechaCotizaToDetalle: this.monedaObject.fechaCotizaToDetalle,
            monedaDefecto: this.monedaDefecto,
            monedaProveedor: selectMoneda,
            goBackName: "CuentaCorrienteProveedores",
          },
        });
      }
    },
    goToDeleteComprobante(item) {
      this.openModalDelete = true;
      this.paramsToDelete = {
        movProvId: item.mProvId,
        isCtaCte: true,
        monedaToDetalle: this.monedaObject.monedaToDetalle,
        fechaCotizaToDetalle: this.monedaObject.fechaCotizaToDetalle,
      };
    },
    goToRevertOp(item) {
      this.movProvIdToRevert = item.mProvId;
      this.openModalRevertOp = true;
    },
    goToNotaCredito(mProvId) {
      this.$router.push({
        name: "NuevaNotaDeCredito",
        params: {
          movProvId: mProvId,
          empresaSelected: this.empresaSelected
            ? this.empresaSelected.id
            : null,
          provCod: this.proveedorSelected
            ? this.proveedorSelected.value2
            : null,
          fechaDesde: this.fechaDesde,
          fechaHasta: this.fechaHasta,
          routeToGoBack: "CuentaCorrienteProveedores",
        },
      });
    },
    async goToOrdenPago(mProvId) {
      this.$router.push({
        name: "NuevaOrdenDePago",
        params: {
          mProvId: mProvId,
          tipoEdicion:
            mProvId == undefined ? null : await this.canEditTotalOp(mProvId),
          empresaSelected: this.empresaSelected
            ? this.empresaSelected.id
            : null,
          provCod: this.proveedorSelected
            ? this.proveedorSelected.value2
            : null,
          fechaDesde: this.fechaDesde,
          fechaHasta: this.fechaHasta,
          routeToGoBack: "CuentaCorrienteProveedores",
        },
      });
    },
    openAsignarCompLibroIva(item) {
      this.itemToAsigCompLibroIva = item;
      this.openModalAsigCompIva = true;
    },
    closeModalFirst() {
      this.openModalDelete = false;
      this.$emit("closeCompCancelatorios");
    },
    closeModalCompIva() {
      this.openModalAsigCompIva = false;
    },
    closeModal() {
      this.$emit("closeCompCancelatorios");
    },
  },
};
</script>

<style scoped></style>
